<template>
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-dark">
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" :href="baseUrl">
        <b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img :src="baseUrl+'/img/logo-icon-v2.png'" alt="homepage" class="dark-logo " />
          <!-- Light Logo icon -->
          <!-- <img :src="baseUrl+'/img/logo-light-icon.png'" alt="homepage" class="light-logo'" / -->
        </b>
        <!--End Logo icon -->
        <span>
          <!-- dark Logo text -->
          <img :src="baseUrl+'/img/logo-text.png'" alt="homepage" class="dark-logo logo_custom_nav" />
          <!-- Light Logo text -->    
          <img :src="baseUrl+'/img/logo-light-text.png'" class="light-logo" alt="homepage" />
        </span> 
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav mr-auto">
        <!-- This is  -->
        <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
        <li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->
      </ul>
    </div>
  </nav>
</header>
</template>

<script>
import GlobalVue from '@libs/Global'
let $ = global.jQuery

export default{
  extends:GlobalVue,
  mounted() {
    $(".sidebartoggler").on('click', function () {
        if ($("body").hasClass("mini-sidebar")) {
            $("body").trigger("resize");
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
        }
        else {
            $("body").trigger("resize");
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
        }
    });
    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").click(function () {
        $("body").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("ti-menu");
        $(".nav-toggler i").addClass("ti-close");
    });
  },
}
</script>