<template>
<div id="main-wrapper">
  <slot name="header">
    <Header></Header>
  </slot>
  <slot name="sidebar">
    <Sidebar></Sidebar>
  </slot>
  <div class="page-wrapper">
    <router-view/>
    <slot name="loader"></slot>
  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import Header from '@view/include/Header'
import Sidebar from '@view/include/Sidebar'

export default {
  extends:GlobalVue,
  components:{Header, Sidebar},
}
</script>