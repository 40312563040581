<template>
<aside class="left-sidebar">
  <div class="scroll-sidebar">
    <div class="user-profile">
      <div class="user-pro-body">
        <div>
          <img :src="user.avatar == '' || user.avatar == null ? baseUrl+'/img/user-default.png' : apiUrl + '/uploader/' + (user.avatar || '').removeWebp()" 
          alt="user-img" 
          class="img-circle" >
        </div>
        <div class="dropdown">
          <a 
            href="javascript:void(0)" 
            class="dropdown-toggle u-dropdown link hide-menu" 
            data-toggle="dropdown" 
            role="button"
            aria-haspopup="true" aria-expanded="false">
            {{user.username}}<span class="caret"></span>
          </a>
          <div class="dropdown-menu animated flipInY">
            <div class="dropdown-divider"></div>
            <router-link :to="{name:'Account', params:{pageSlug: user.id}}"
              class="dropdown-item">            
                <i class="ti-settings"></i> Account Setting
            </router-link>
            <div class="dropdown-divider"></div>
            <a 
              @click="doLogout"
              href="javascript::" 
              class="dropdown-item">
              <i class="fa fa-power-off"></i> Logout
            </a>
          </div>
        </div>
      </div>
    </div>
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <template v-for="(val, key) in menuData">
					<li 
            v-if="val.head && val.isPermit" 
            :key="key" 
            class="nav-small-cap text-uppercase font-size-xs line-height-xs ">
            --- {{ val.head }}
					</li>
					<li 
            v-else-if="(val.child||[]).length && val.isPermit"
            :class=" $route.name == val.child[0].link || $route.name == val.child[1].link ? 'active' : ''"
            @click="clickMenu($event)"
            :key="key"
          >
            <a 
              href="javascript:;"
              class="has-arrow waves-effect waves-dark"
            >
              <i :class="val.icon" id="icon"></i>
              <span class="hide-menu" id="span">
                {{ val.name }}
              </span>
            </a>
            <ul 
              class="collapse" 
              :data-submenu-title="val.name">
                <template v-for="(val2, key2) in val.child">
                  <li 
                    v-if="val2.isPermit" 
                    :key="key2" 
                    class="nav-item"
                    @click="setLs(val2.module)"
                  >
                    <router-link class="active-child" :to="{name:val2.link}" >
                      {{ val2.name }}
                    </router-link>
                  </li>
                </template> 
            </ul>
					</li>
					<li   
            @click="setLs(val.module)"
            v-else-if="val.isPermit" 
            :key="key" 
            :class="val.link === $route.name ? 'active' : '' "
            >
						<router-link 
              :to="{name:val.link}" 
              :title="val.name"
              :class="val.link === $route.name ? 'waves-effect waves-dark active' : 'waves-effect waves-dark'"  aria-expanded="false"
            >
							<i :class="val.icon"></i>
							<span class="hide-menu"> {{ val.name }} </span>
						</router-link>
					</li>
				</template>
      </ul>
    </nav>
  </div>
</aside>
</template>

<script>
import GlobalVue from '@libs/Global'
let $ = global.jQuery
export default{
  extends:GlobalVue,
  data(){
    return {
      loaded:false,
      isActiveChild : false,
      main : [],
      menu:[
				{ name:"Dashboard", module:"Dashboard", link:"Dashboard", icon:"icon-speedometer" },

				{ head:"Main"},
				{ name:"Home Page", module:"Home", link:"Home", icon:"ti-home", category: "Main"},
				{ name:"About", module:"About", link:"About", icon:"icon-grid", category: "Main" },
        { name:"Services", module: "Services", link: "Services", icon: "ti-bookmark-alt", category: "Main" },
        { name:"Our Work", module: "OurWork", link: "OurWork", icon: "ti-image", category: "Main" },
        { name:"Blog", isPermit: false , module:"ArticleParent", icon:"ti-layout-list-thumb", category: "Main", 
					child:[
						{name:"Blog List", module:"Article", link:"Article", Parent: "Blog", category: "Main"},
						{name:"Blog Category", module:"ArticleCategory", link:"ArticleCategory", Parent: "Blog", category: "Main"},
					] 
				},     

        
        { head:"Others" },
        { name:"Contact Us", isPermit: false , module:"Contact", icon:"ti-email", category: "Others",
					child:[
            { name:"Contact Page", module:"Contact", link:"Contact", Parent: "Contact Us", category: "Others"},
            { name:"Contact Message", module:"ContactMessage", link:"ContactMessage", Parent: "Contact Us", category: "Others"},
					] 
				},     
        { name:"Testimonial", module: "Testimonial", link: "Testimonial", icon:"ti-comment-alt", category: "Others"},
        { name:"Clients", module: "Clients", link: "Clients", icon:"ti-layers-alt", category: "Others"},
        { name:"Career", module:"Career", link:"Career", icon:"ti-briefcase", category: "Others"},
        { name:"Static Page", module:"Legal", link:"Legal", icon:"ti-archive", category: "Others"},
        { name:"Member", module:"Member", link:"Member", icon:"icon-people", category: "Others"},
					

        { head:"Setting"},
        { name:"Users Management", isPermit: false , module:"UsersManagement", icon:"icon-user-follow", category: "Setting", 
					child:[
						{name:"Users List", module:"Users", link:"Users", Parent: "Users Management", category: "Setting"},
						{name:"User Level", module:"UserLevel", link:"UserLevel", Parent: "Users Management", category: "Setting"},
					] 
				},
        { name:"AuditTrail", module:"AuditTrail", link:"AuditTrail", icon:"icon-check" },
      ]
    }
  },
  mounted(){
    $(".scroll-sidebar, .right-side-panel, .message-center, .right-sidebar").perfectScrollbar()

    setTimeout(() => {
      document.title = `Backoffice - ${this.$route.meta.pageTitle} | Illuminate`
    }, 0)
  },
  computed:{
		menuData(){
			if(!this.user.id) return false
      let menu = this.menu;
      let heads = []
      menu.map((v)=>{
        v.isPermit = v.module ? this.user.menuRole.indexOf(v.module)>-1 : true
        
        if(this.user.levelId==1) v.isPermit = true
        if(!v.link) v.link = v.module

        if(v.head){
          heads.push({menu: v.head, child: 0});
        }

        // alert(v.name)

        if(v.child) v.child.map((v2)=>{
            v2.isPermit = v2.module ? this.user.menuRole.indexOf(v2.module)>-1 : true
            if(this.user.levelId==1) v2.isPermit = true
            if(!v2.link) v2.link = v2.module;
            if(v2.isPermit == true) {
                let index = this.menu.findIndex(x => x.name === v2.Parent);
                this.menu[index].isPermit = true;
            }
          })
        
        
        if(v.isPermit){
          const index = heads.findIndex((arr) => arr.menu == v.category)
          if(index > -1) {
            heads[index].child += 1
          }
        }

      })
      heads.forEach(head => {
        const index = menu.findIndex((men) => men.head == head.menu);
        if(head.child == 0){
          menu[index].isPermit = false
        }
      })
      return menu
		}
	},
  methods:{
    setLs(module){
      let confSeo = JSON.stringify({ menu : module, val : "N"})
      localStorage.setItem("SEO", confSeo)
    },
    clickMenu(e){
      let tarwal = $(e.target);
      let ic = tarwal[0].id;
      let target;

      if(ic === "icon"){
        target = $(e.target).parent().parent()            
      }
      else if(ic === "span"){
        target = $(e.target).parent()            
      }
      else{
        target = $(e.target)
      }
      
      if (!target.hasClass("active")) { 
        // hide any open menus and remove all other classes
        $("ul", target.parents("ul:first")).removeClass("in");
        $("a", target.parents("ul:first")).removeClass("active");
        target.next("ul").addClass("in");
        target.addClass("active");
        this.isActiveChild = true;
      }
      else if (target.hasClass("active")) {

        target.parents("ul:first").removeClass("active");
        target.next("ul").removeClass("in");
        target.removeClass("active");      
        this.isActiveChild = false;
      }
    }
  },
  watch:{
    $route(){
      setTimeout(() => {
        document.title = `Backoffice - ${this.$route.meta.pageTitle} | Illuminate`
      }, 0)
    }
	}
}
</script>